import React from "react"
import { theme } from "../Styles/theme"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import Layout from "../Components/layout"
import { HeadingPrimary } from "../Styles/headers"
import { Section } from "../Styles/section"
import { GlobalStyles } from "../Styles/global"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const NavbarMenuLink = css`
  color: black;
  font-size: ${theme.text.text200};
  font-family: "Oswald", sans-serif;
`

const BtnMedium = css`
  background: ${theme.colors.primary};
  padding: ${theme.pm.pm100} ${theme.pm.pm200};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: ${theme.text.text200};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`

export default () => (
  <ThemeProvider theme={theme}>
    <Global styles={GlobalStyles} />
    <Layout>
      <Section backgroundColor="secondary" style={{ paddingTop: "150px" }}>
        <div style={{ margin: "0 auto", maxWidth: "600px" }}>
          <HeadingPrimary>Get in touch</HeadingPrimary>
          <p>
            Send an email to tenzin@whatsdev.com
          </p>
          <div id="motion-extension" wfd-id={0}>
            <div className="fill" wfd-id={1}>
              <iframe
                id="motion-intervention-iframe"
                className="motion-iframe"
                style={{ visibility: "hidden" }}
                title="contact"
              />
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  </ThemeProvider>
)
